.cursor-pointer{
    cursor: pointer;
}

.admin-loaer-group{
    position: relative;
    top: 0;
    left: 0;
}

.admin-loaer-background-img{
    position: relative;
    width:100%;
    top: 0;
    left: 0;
}


/*PLATFORM FROM ----------------------------- */
.platform-form-logo-preview{
    width: 100%;
}
/*EVENT FROM ----------------------------- */

.admin-loaer-background-logo{
    position: absolute;
    width:25%;
    top: 30px;
    left: 30px;
}