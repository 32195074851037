.text-color {
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.pointer-white {
  color: white;
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-unset {
  cursor: unset;
}

.content-over-img {
  position: "absolute";
  top: "62%";
  left: "50%";
  transform: "translate(-50%, -50%)";
}

.evnt-card-title {
  color: white;
  font-size: 22px;
}

@media (max-width: 320px) {
  .evnt-card-title {
    color: white;
    font-size: 18px;
  }
}

/* PERSPECTIVE LOADER ---------------------------------------------------- */
.perspective-loader {
  margin-top: -130px;
  margin-left: -15px;
  z-index: 6;
  position: fixed;
  display: "flex";
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.perspective-loader-main-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
}

/* LOADER IMG ---------------------------------------------------- */
.evnt-loader-data {
  margin: 0;
  position: absolute;
  top: 50% !important;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  bottom: auto !important;
  height: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
}

.event-loader-data .login {
  right: 37.5%;
  left: 37.5%;
}

.evnt-loader-360-img {
  max-width: 500px;
}

@media (max-width: 1000px) {
  .evnt-loader-360-img {
    max-width: 300px;
    bottom: 15px;
  }
}

.evnt-loader-logo {
  max-height: 500px;
  margin-bottom: 15px;
}

@media (max-width: 1000px) {
  .evnt-loader-logo {
    max-height: 200px;
    margin-bottom: 15px;
  }
}

.evnt-loader-text {
  color: white;
}

/* HEADER BAR ---------------------------------------------------- */
.header-logo {
  width: 50px;
  height: 50px;
  padding-right: 10px;
}

@media (max-width: 425px) {
  .header-logo {
    padding-right: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-30%);
    transform: translateY(-30%);
  }

  .header-match-counter {
    padding-left: 70px;
  }
}

/* BOTTOM MENU BAR ---------------------------------------------------- */
.bottom-menu-bar {
  margin: auto;
}

.bottom-menu-bar li :first-child {
  margin-bottom: -10px !important;
}

.bottom-menu-bar li {
  margin: 0px 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-30%);
}

/* LAYER 2D CARDS ----------------------------------------------- */
.layer-2d {
  z-index: 7;
  pointer-events: none;
  align-items: center;
  flex-direction: row;
  position: fixed;
  display: flex;
  margin-top: -130px;
  margin-right: 0px;
  width: 100%;
  height: 100%;
}

@media (max-height: 648px) {
  .layer-2d {
    justify-content: space-around;
  }
}

/* ----------------------------------------------- */
.evnt-card {
  pointer-events: all;
  height: auto;
  margin: 15px 30px;
}

.evnt-card-sm {
  width: 300px;
}

.evnt-card-md {
  width: 400px;
}

.evnt-card-lg {
  width: 600px;
}

@media (max-width: 425px) {
  .evnt-card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90% !important;
    height: auto;
  }
}

.evnt-card-title {
  text-decoration-line: underline;
}

@media (max-width: 1000px) and (min-width: 768px) {
  .evnt-card-title {
    font-size: 24px;
  }
}

.evnt-card-item-title {
  padding-top: 5px;
  font-size: 24px;
  color: white;
  display: block;
}

.log-in-form {
  display: grid;
}

/*
    CARDS INDEX:
    1.- LOG IN CARD
    2.- LOCATIONS & PERSPECTIVES CARDS
    3.- MORE CARD
    4.- VIP SUITE CARD
    5.- TEAM STATISTICS CARD
    6.- SETTINGS AND MORE CARD
    7.- PAYMENT CARD
    8.- AUDIO CONTROLS
    10.- CONTACTS 360
*/

/* 1.- LOG IN CARD -------------------------------*/
.layer-2d-log-in {
  z-index: 7;
  pointer-events: none;
  align-items: center;
  flex-direction: row;
  position: fixed;
  display: flex;
  margin-top: -130px;
  margin-right: 0px;
  width: 100%;
  height: 100%;
}

@media (max-width: 425px) {
  .layer-2d-log-in {
    display: block;
  }
}

.log-in-card {
  margin: 15px 30px;
  height: 460px;
}
@media (max-width: 425px) {
  .log-in-card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 90%;
  }
}

.logo-block {
  display: table-caption;
}

.log-in-logo {
  width: 100%;
}

.log-in-catalog {
  margin: 15px 30px;
  height: 250px;
  width: 70%;
  margin-bottom: -350px;
}

.sign-in-card {
  margin: 15px 30px;
  height: auto;
}

.sign-in-logo {
  width: 50%;
}

.sign-in-avatar {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (max-width: 425px) {
  .sign-in-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 140px;
  }
}

/* 2.- LOCATIONS & PERSPECTIVES CARDS ---------------------------------------------------- */

.location-item-layout {
    position: relative;
    color: white;
}

@media (max-height: 648px) {
  .location-item-layout {
    display: flex;
  }
}

.location-thumbnail {
  width: auto;
  max-width: 450px;
}

.location-data {
    position: absolute;
    width: 100%;
    bottom: 0px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgb(0, 0, 0) 100%);

}

.location-data-horizontal {
  display: flex;
}

@media (max-height: 648px) {
  .location-data-horizontal {
    display: block;
  }
}

.location-data-list {
  display: inline;
}

.location-data-list li {
  display: inline;
  margin-right: 15px;
}

.location-button {
  margin-top: 5px;
  width: 100%;
}

/* 3.- MORE CARD ---------------------------------------------------- */

.icon-bnt {
  margin-bottom: 5px;
}

/* 4.- VIP SUITE CARD ------------------------------------------ */
.vip-suite-video {
  display: block;
  padding: 5px;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 425px) {
  .vip-suite-video {
    width: 100%;
  }
}

/* 5.- TEAM STATISTICS CARD ------------------------------------------ */

.team-statistics-card {
  display: flex !important;
}

@media (max-width: 425px) {
  .team-statistics-card {
    display: block !important;
  }
}

.evnt-statistics-menu {
  background-color: rgb(82 82 82 / 50%);
  border-radius: 5px;
  display: block;
}

@media (max-width: 425px) {
  .evnt-statistics-menu {
    display: flex;
  }
}

.evnt-statistics-data {
  margin-left: 15px;
  width: 400px;
}

.evnt-statistics-table {
  background-color: rgb(82 82 82 / 50%);
  border-radius: 10px;
}

@media (max-width: 425px) {
  .evnt-statistics-data {
    margin-left: 0px;
    width: 100%;
  }
}

.vr-statistics-button {
  bottom: 120px;
  top: auto !important;
  height: auto !important;
  margin-left: 20% !important;
  width: 60% !important;
  margin-right: 20% !important;
}

@media (max-width: 425px) {
  .vr-statistics-button {
    bottom: 70px;
  }
}

/* 6.- SETTINGS AND MORE CARD ------------------------------------------ */

/* 7.- UPGRADE PERSPECTIVE --------------------------------------------- */
.upgrade-perspective-layout {
  display: flex;
}

/* 8.- PAYMENT CARD --------------------------------------------- */

.card-thumbnail {
  width: 100%;
  max-width: 100%;
  top: 15px;
  display: block;
  margin-left: "auto";
  margin-right: "auto";
  cursor: "pointer";
}

@media (max-height: 648px) {
  .card-thumbnail {
    width: 170px;
    max-width: 170px;
  }
}

@media (max-width: 425px) {
  .card-thumbnail {
    width: 300px;
    max-width: 300px;
  }
}

.payment-card-field {
  margin-top: 10px;
}

.card-data-row {
  display: flex;
}

/* 9.- AUDIO CONTROLS --------------------------------------------- */

.evnt-audio-casters {
  width: 100%;
}

.evnt-audio-levels {
  margin-left: 0px;
  width: 100%;
}

@media (max-height: 648px) {
  .evnt-audio-levels {
    margin-left: 15px;
  }
}

/* 10.- CONTACTS 360 --------------------------------------------- */
.contact-thumbnail {
  cursor: pointer;
  padding-bottom: 10px;
}

.contact-info {
  display: grid;
}

.contact-name {
  color: white;
  font-size: 14;
}

.contact-data {
  display: inline-block;
}

.contact-actions {
  display: flex;
}
.contact-actions li {
  color: white;
  margin: 0px 5px;
}
